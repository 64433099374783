// import clone from '@/utils/clone';

// 获取对象类型
export function getObjType(obj) {
  let toString = Object.prototype.toString;
  let map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  return map[toString.call(obj)];
}

// 分类产品数据合并
export function setTimeoutStoreFn(fnArr, callback) {
  for (let i = 0; i < fnArr.length; i++) {
    fnArr[i].call();
  }

  setTimeout(() => {
    callback.call();
  }, 250);
}

// 判断是否为空
export function isEmpty(fData) {
  return (
    fData === null || fData === undefined || fData === "" || fData.length === 0
  );
}

// 监测 Dom 元素加载状态
export function loadDomCheck() {
  let args = Array.prototype.slice.call(arguments),
    loadTimer = null;

  let promise = new Promise(function (resolve) {
    function fn() {
      loadTimer && window.cancelAnimationFrame(loadTimer);
      loadTimer = null;

      let isLoaded = false,
        _len = 0;

      if (args[1] === "id") {
        isLoaded = document.getElementById(args[0]) ? true : false;
      } else {
        _len = document.querySelectorAll("." + args[0]).length;

        if (args[2] && args[2] > 0) {
          isLoaded = _len === args[2] ? true : false;
        } else {
          isLoaded = _len ? true : false;
        }
      }

      if (isLoaded) {
        resolve(true);
      } else {
        loadTimer = window.requestAnimationFrame(fn);
      }
    }

    fn();
  });

  return promise;
}

export let allTypesObj = {
  0: "不限",
  1: "酒店",
  2: "门票",
  4: "美食",
  5: "门票",
  10: "跟团游",
};

// 团队游类型列表
export let tuanTypesList = [11, 12, 13, 14, 15];
export let tuanTypesObj = {
  11: "自由行",
  12: "跟团游",
  13: "私家团",
  14: "半跟团",
  15: "当地参团",
};

// 过滤出模块对于类型
export function filterModuleType(productType) {
  productType = Number(productType);

  let allType = 0;
  let hotelType = 1;
  let ticketsType = 2;
  let foodType = 4;
  let ticketsPlusType = 5;
  let tuanType = 100;

  if ([1].includes(productType)) {
    return hotelType;
  } else if ([2].includes(productType)) {
    return ticketsType;
  } else if ([4].includes(productType)) {
    return foodType;
  } else if ([5].includes(productType)) {
    return ticketsPlusType;
  } else if (tuanTypesList.includes(productType)) {
    return tuanType;
  } else {
    return allType;
  }
}

// 限制字符长度
export function getMaxStr(str, max) {
  if (str.length > max) {
    return str.substring(0, max) + "...";
  }
  return str;
}
