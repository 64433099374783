// import dt from "@/utils/datetime";
// import clone from "@/utils/clone";
import localStorage from "@/utils/localStorage";
import router from "@/router";

let pageFromName = "HLTRIP_PAGE_From";
let targetCityName = "HLTRIP_PAGE_City";
let originCityName = "HLTRIP_PAGE_Origin_City";
let productTypeName = "HLTRIP_PAGE_ProductType";
let recentCityName = "HLTRIP_PAGE_Recent_City";

const DefaultCiy = {
  cityName: "北京",
  cityCode: "110100000000",
  default: true
};

export const enumerate = {
  pageFromName,
  targetCityName,
  originCityName,
  productTypeName,
  recentCityName,
}

// from: h5 / mg 。 有控制台页面在引用
export function setPageFrom(from) {
  localStorage.set(pageFromName, from);
}

// from: h5 / mg 。 有控制台页面在引用
export function getPageFrom() {
  return localStorage.get(pageFromName) || "h5";
}

// 设置目标城市数据
export function setCity({ cityName, cityCode }) {
  localStorage.set(targetCityName, {
    cityName,
    cityCode,
  });
}

// 设置出发城市数据
export function setOriginCity({ cityName, cityCode }) {
  localStorage.set(originCityName, {
    cityName,
    cityCode,
  });
}

// 获取目标城市数据
export function getCity() {
  return localStorage.get(targetCityName) || DefaultCiy;
}

// 获取出发城市数据(优先从Url获取)
export function getOriginCity() {
  // url数据
  let transferCity = undefined;
  if (
    router.currentRoute.query.cityName &&
    router.currentRoute.query.cityCode
  ) {
    transferCity = {
      cityName: router.currentRoute.query.cityName,
      cityCode: router.currentRoute.query.cityCode,
    };
  }
  // storage数据
  const storageCity =
    localStorage.get(originCityName) &&
    localStorage.get(originCityName).cityName &&
    localStorage.get(originCityName).cityCode
      ? localStorage.get(originCityName)
      : undefined;
  return transferCity || storageCity || DefaultCiy;
}

// 设置 产品类型  0: 不限, 1：酒店+, 2：门票, 4:餐饮, 5:门票+, 11: "自由行" , 12：跟团游, 13: "私家团", 14: "半跟团", 15: "当地参团"
export function setProductType({ productType }) {
  localStorage.set(productTypeName, productType);
}

// 产品类型 0: 不限, 1：酒店+, 2：门票, 4:餐饮, 5:门票+, 11: "自由行" , 12：跟团游, 13: "私家团", 14: "半跟团", 15: "当地参团"
export function getProductType() {
  return localStorage.get(productTypeName) || 0;
}

// 设置最近使用的城市
export function setRecentCity(cityArr) {
  localStorage.set(recentCityName, cityArr);
}

// 最近使用的城市
export function getRecentCity() {
  return localStorage.get(recentCityName) || [];
}

// 清理最近使用的城市
export function clearRecentCity() {
  localStorage.set(recentCityName, []);
}
