<template>
  <div class="page" :style="isIos==1?{}:{'padding-top':'44px'}">

    <!-- 搜索栏 -->
    <!-- <div class="search-bar-wrapper" :class="[isSearchMode ? 'active' : 'inactive']"> -->
    <div class="search-bar-wrapper active">
      <!-- <div class="backICon" @click="close"></div> -->
      <img src="@/assets/list/searchBackIcon.png" class="backICon" @click="close">
      <div class="search-bar">
        <img v-if="isShowSearchIcon" class="search-bar-icon-0" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/fangdajing1@3x.png" />
        <form action="" @submit="handleSubmit" ref="form">
          <input class="search-bar-input" v-model="keyword" ref="input" type="search" :placeholder="placeholder" @focus="onFocusInput" @input="onInput" @compositionstart="onCompositionStart"
                 @compositionend="onCompositionEnd" @keydown.enter="searchInput" />
        </form>
        <!-- <img v-show="inputValue" class="search-bar-icon-1" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/close-2@3x.png" @click="onClickClearInput" /> -->
      </div>
      <div class="search-bar-cancel" @click="searchInput">搜索</div>
    </div>

    <div class="main-body">

      <!-- 城市选择器 -->
      <div v-if="!showSearchResult&&dep!==0" class="m-body">
        <div v-if="tabIndex === 0" class="m-body-dom">

          <div class="m-content" @scroll="onScrollDom" ref="mcontDom">
            <!-- 热门城市 -->
            <div v-if="domHotCity.length > 0">
              <div class="group-hd">
                <div class="group-name">热门城市</div>
              </div>
              <div class="group-items">
                <div class="group-item fx-center" :class="[it.code === currentCode ? 'hl-cur-item' : '']" v-for="(it, j) in domHotCity" :key="it.name + j" @click="onClickDomHotCity(j)">
                  <div class="cell-name" :class="[it.textlength ? 'cell-text-' + it.textlength : '']">{{ it.shortname || it.name }}</div>
                  <div v-if="it.tag" class="group-item-tag" :style="{ background: it.tag.bg }">{{ it.tag.n }}</div>
                </div>
              </div>
            </div>
            <!-- 字母索引 -->
            <div v-if="domGroups.length > 0">
              <div class="group-hd">
                <div class="group-name">字母索引</div>
              </div>
              <div class="group-items">
                <div class="group-item-index" v-for="(g, j) in domGroups" :key="g.name + j" @click="onClickDomGroupIndex(j)">
                  <div>{{ g.name }}</div>
                </div>
              </div>
            </div>
            <!-- 城市 Grid List -->
            <div :id="'dom-group__' + i" v-for="(g, i) in domGroups" :key="g.name + i">
              <div class="group-hd">
                <div class="group-name">{{ g.name }}</div>
              </div>
              <div class="group-items">
                <div class="group-item fx-center" :class="[it.code === currentCode ? 'hl-cur-item' : '']" v-for="(it, j) in g.items" :key="it.name + j" @click="onClickDomItem(i, j)">
                  <div class="cell-name" :class="[it.textlength ? 'cell-text-' + it.textlength : '']">{{ it.shortname || it.name }}</div>
                  <div v-if="it.tag" class="group-item-tag" :style="{ background: it.tag.bg }">{{ it.tag.n }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- 返回顶部 -->
          <div v-if="showScrollToTopDom" class="scroll-to-top" @click="onClickScrollToTopDom">
            <img class="scroll-to-top-img" src="./top.png" />
          </div>

        </div>
      </div>
      <div v-if="!showSearchResult&&dep===0" class="new-body">
        <div class="defaultList" v-for="(item,index) in defaultList" :key="index+'defaultList'">
          <div class="defaultList-title">{{item.title}}</div>
          <div class="defaultList-list">
            <div class="defaultList-list-item" v-for="(v,i) in item.recommendations" :key="i+'recommendations'" @click="onClickSearchItemV2(v)">
              {{v.cityName? v.cityName:v.content}}
            </div>
          </div>
        </div>
      </div>
      <div class="search-res-overlay" :class="[isSearchMode ? '' : 'hidden']" @click="onClickCancelSearch" @touchmove.prevent></div>

      <!-- 搜索结果 -->
      <div v-if="showSearchResult" class="search-res">
        <!-- Placeholder -->
        <div v-if="result.length === 0" class="search-no-result">
          <img src="./noresult@3x.png" class="search-no-result-img" />
          <div class="search-no-result-txt">搜索无结果</div>
        </div>

        <!-- 搜索结果 -->
        <!-- 出发地城市选择 -->
        <div v-if="dep===1">
          <div v-for="item in result" :key="item.code">
            <div class="si-1" @click="onClickSearchItem(item)">
              <div class="si-main">
                <div class="fx-row">
                  <span v-if="item.cityFlag == 1" class="si-text-hl">{{ item.cityName }}</span>
                  <span v-else>{{ item.productName }}</span>
                </div>
              </div>
              <div v-if="item.hot" class="si-hot-tag">热门</div>
            </div>
          </div>
        </div>
        <div v-if="dep===0">
          <div v-for="item in result" :key="item.code">
            <div class="si-1" @click="onClickSearchItemV2(item)">
              <div class="si-main">
                <div class="fx-row">
                  <img :src="item.icon" class="imgIcon">
                  <!-- <span v-if="item.type == 1" class="si-text-hl">{{ item.cityName }}</span> -->
                  <span v-html="showKeyWords(item)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import get from "@/utils/get";
import * as LocalData from "@/service/localData";
import _ from "lodash";
import { CATEGORY } from "@/service/enumerate";
import env from "@/utils/env";

import service from "../../../service";

const HOT_CITY = [
  "北京",
  "上海",
  "成都",
  "广州",
  "深圳",
  "杭州",
  "重庆",
  "南京",
  "西安",
  "天津",
  "昆明",
  "武汉",
  "沈阳",
  "郑州",
  "哈尔滨",
  "长沙",
];

export default {
  props: {
    dep: { type: Number, default: 0 },
    placeholder: { type: String, default: "搜索城市名称（中文或拼音）" },
    typeOfBusiness: {
      type: Number, // 0: "不限", 1: "酒店", 2: "门票",4: "美食",  10: "跟团游",11: "酒店套餐" 13:'综合页'
      default: 0,
    },
    pageCityObj: {
      type: Object,
      default: () => {
        return {
          cityName:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityName
              : LocalData.getCity().cityName,
          cityCode:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityCode
              : LocalData.getCity().cityCode,
        };
      },
    },
  },
  data() {
    return {
      isNative: env.isNative,
      tabs: ["国内", "国际/中国港澳台"],
      tabIndex: 0,
      isSearchMode: false,
      inputValue: "",
      navs: [],
      navIndex: 0,
      showScrollToTopInt: false,
      intGroups: [],
      result: [],
      showSearchResult: false,
      recent: [],
      domHotCity: [],
      domGroups: [],
      showScrollToTopDom: false,
      currentCode: "",
      defaultList: [],
      keyword: "", //搜索词
    };
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
    isShowSearchIcon() {
      if (this.isNative && env.os == "ios") {
        return false;
      } else {
        return true;
      }
    },
    InitCityName() {
      return LocalData.getOriginCity().cityName;
    },
    InitCityCode() {
      return LocalData.getOriginCity().cityCode;
    },
    InitDepCityName() {
      return this.pageCityObj.cityName;
    },
    InitDepCityCode() {
      return this.pageCityObj.cityCode;
    },
  },
  created() {
    // dir: 'dep' | 'arr'
    // org: 选择到达城市时传出发地三字码
    // currentCode: 当前选中的三字码
    // callback: 选择成功回调
    const { dir, org, currentCode, callback } = this.$route.params;
    this.__dir = dir;
    this.__org = org;
    this.__callback = callback;
    this.__search_id = 0;
    this.__search_timer = null;

    if (currentCode) {
      this.currentCode = currentCode;
    }
    this.recent = LocalData.getRecentCity();
    this.loadDomData();
  },

  mounted() {
    const title = {
      dep: "选择出发城市",
      arr: "选择到达城市",
    };
    this.$updateTitle(title[this.__dir] || "选择城市");
  },

  beforeDestroy() {
    this.__search_id = -1;
    clearTimeout(this.__search_timer);
  },
  beforeCreate() {
    this.keyword = "";
  },
  methods: {
    refreshKeyword() {
      this.keyword = "";
    },
    //
    searchInput() {
      if (this.typeOfBusiness == 0) {
        const page = {
          url: "/",
          query: {
            analyseSourceEntry: env.hlsource || "",
            keyword: encodeURIComponent(this.keyword),
          },
        };
        page.url = "/common-list";
        page.name = "common-list";
        this.$page.push(page);
      } else if (this.typeOfBusiness == 2) {
        this.$emit("search", { value: this.keyword });
      } else if (this.typeOfBusiness == 13) {
        this.$emit("search", { value: this.keyword });
      }
    },
    //关键词显示
    showKeyWords(item) {
      return item.content.replace(
        item.match,
        `<span style="color:#1985FF">${item.match}</span>`
      );
    },
    //关掉搜索页
    close() {
      this.$emit("close");
    },
    //获取门票 首页 综合页默认推荐
    getDefaultList(item) {
      console.log("pageCity----", item);
      let params = {};
      //首页和综合页默认推荐
      if (this.typeOfBusiness == 0 || this.typeOfBusiness == 13) {
        if (this.typeOfBusiness == 0) {
          params = {
            position: 1,
          };
        } else if (this.typeOfBusiness == 13) {
          params = {
            position: 2,
          };
        }
        service
          .r215066(params)
          .then((r) => get(r, "res.bd.data"))
          .then((r) => {
            // console.log("DefaultList-----------", r);
            this.defaultList = r;
          });
      }
      //门票默认推荐
      if (this.typeOfBusiness == 2) {
        // console.log(
        //   "LocalData.getCity().cityCode---",
        //   LocalData.getCity().cityCode
        // );
        // console.log(
        //   "LocalData.getCity().cityName---",
        //   LocalData.getCity().cityName
        // );
        params = { depCityCode: item.cityCode };
        service
          .r215068(params)
          .then((r) => get(r, "res.bd.data"))
          .then((r) => {
            // console.log("DefaultList-----------", r);
            this.defaultList = r;
          });
      }
    },
    /*
            数据结构：
            code 城市码
            cityName 城市名
            jianpin 简拼
            pinyin 拼音
            hot 是否热门城市 1：是 0：否
            initials 首字母
        */

    loadDomData() {
      if (this.__dom_loaded || this.__dom_loading) return;
      this.__dom_loading = true;
      service
        .r215002({
          dep: this.dep,
        })
        .then((r) => {
          let list = get(r, "res.bd.data", []);
          let groups = [];
          let A = "A".charCodeAt(0);
          let Z = "Z".charCodeAt(0);
          for (let i = A; i <= Z; i++) {
            groups.push({
              name: String.fromCharCode(i),
              items: [],
            });
          }

          let hotCity = [];
          list.forEach((it) => {
            // 测试线无initials字段，不展示默认城市列表
            const idx = it.initials.toUpperCase().charCodeAt(0) - A;
            if (idx >= 0 && idx < 26) {
              let hot_idx = HOT_CITY.indexOf(it.cityName);
              if (hot_idx >= 0) {
                hotCity[hot_idx] = {
                  type: 4,
                  name: it.cityName,
                  shortname: it.cityName,
                  textlength: it.cityName && it.cityName.length,
                  code: it.cityCode,
                  international: 0,
                  url: it.url,
                };
              }

              groups[idx].items.push({
                type: 4,
                name: it.cityName,
                shortname: it.cityName,
                textlength: it.cityName && it.cityName.length,
                code: it.cityCode,
                international: 0,
                url: it.url,
              });
            } else {
              console.warn(`城市数据错误: ${JSON.stringify(it)}`);
            }
          });

          groups = groups.filter((g) => {
            return g.items.length > 0;
          });

          hotCity = hotCity.filter((c) => {
            return !!c;
          });

          this.__dom_loaded = true;
          this.domGroups = groups;
          this.domHotCity = hotCity;
        })
        .catch(() => {
          this.__dom_loading = false;
        });
    },

    // 取消搜索
    onClickCancelSearch() {
      this.clearSearch();
      this.isSearchMode = false;
      this.inputValue = "";
      this.$refs.input.value = "";
    },

    onFocusInput() {
      this.isSearchMode = true;
    },

    onCompositionStart(ev) {
      ev.target.__composing = true;
    },

    onCompositionEnd(ev) {
      ev.target.__composing = false;
      this.__onInput(ev);
    },

    onInput(ev) {
      if (ev.target.__composing) return;
      this.__onInput(ev);
    },

    __onInput(ev) {
      let val = ev.target.value;
      this.inputValue = val;
      this.search(val);
    },

    onClickClearInput() {
      this.clearSearch();
      this.inputValue = "";
      this.$refs.input.value = "";
      this.$refs.input.focus();
    },

    clearSearch() {
      this.__search_id++;
      this.result = [];
      this.showSearchResult = false;
    },

    unsearch() {
      this.clearSearch();
      this.inputValue = "";
      this.$refs.input.value = "";
      this.isSearchMode = false;
    },

    search(value) {
      value = value.replace(/\s/g, "");
      this.keyword = value;
      if (!value) {
        this.clearSearch();
        return;
      }
      console.log("this.InitDepCityName-----", this.InitDepCityName);
      clearTimeout(this.__search_timer);
      this.__search_id++;
      const id = this.__search_id;
      let params = {};

      this.__search_timer = setTimeout(() => {
        if (this.dep == 1) {
          service
            .r215002({
              content: value,
              dep: this.dep, // 查询出发地1 目的地0
              depCity: this.dep ? undefined : this.InitCityName, //出发城市：查目的地时有值
              depCityCode: this.dep ? undefined : this.InitCityCode, //出发城市：查目的地时有值
            })
            .then((r) => {
              if (id !== this.__search_id) return;
              this.result = get(r, "res.bd.data", []);
              // .map(item => ({
              //   raw: item,
              //   label: item
              // }))

              // list = Array.isArray(list) ? list : [];
              // let ls = [].concat(list).map((item) => {
              //   return {
              //     // type: item.type,
              //     // international: item.international,
              //     // click: item.click,
              //     hl: __hl(value, item.cityName),
              //     code: item.cityCode,
              //     name: item.cityName,
              //     // shortname: __shortname(item),
              //     // cname: item.cname,
              //     hot: item.hot,
              //     cityFlag: item.cityFlag,
              //     url: item.url,
              //   };
              // });
              // this.result = ls;

              this.showSearchResult = true;
            });
        } else {
          //首页和综合页默认推荐
          if (this.typeOfBusiness == 0 || this.typeOfBusiness == 13) {
            if (this.typeOfBusiness == 0) {
              params = {
                position: 1,
                keyword: value,
                depCity: this.InitDepCityName,
                depCityCode: this.InitDepCityCode,
              };
            } else if (this.typeOfBusiness == 13) {
              params = {
                position: 2,
                keyword: value,
                depCity: this.InitDepCityName,
                depCityCode: this.InitDepCityCode,
              };
            }
            service
              .r215067(params)
              .then((r) => get(r, "res.bd.data"))
              .then((r) => {
                if (id !== this.__search_id) return;
                this.result = r;
                this.showSearchResult = true;
              });
          }
          //门票默认推荐
          if (this.typeOfBusiness == 2) {
            params = {
              keyword: value,
              arrCity: this.InitDepCityName,
              arrCityCode: this.InitDepCityCode,
            };
            service
              .r215069(params)
              .then((r) => get(r, "res.bd.data"))
              .then((r) => {
                if (id !== this.__search_id) return;
                this.result = r;
                this.showSearchResult = true;
              });
          }
        }
      }, 200);
    },

    onScrollInt(ev) {
      const y = ev.target.scrollTop;
      this.showScrollToTopInt = y >= 100;
    },

    onClickScrollToTopInt() {
      this.$refs.mcontInt.scrollTop = 0;
    },

    onClickIntItem(i, j) {
      const g = this.intGroups[i];
      const item = g && g.items[j];
      if (item) {
        this.confirm(item);
      }
    },

    onScrollDom(ev) {
      const y = ev.target.scrollTop;
      this.showScrollToTopDom = y >= 100;
    },

    onClickScrollToTopDom() {
      this.$refs.mcontDom.scrollTop = 0;
    },

    onClickRecent(i) {
      const item = this.recent[i];
      if (item) {
        this.confirm(item);
      }
    },

    onClickDomHotCity(i) {
      const item = this.domHotCity[i];
      if (item) {
        this.confirm(item);
      }
    },

    onClickDomGroupIndex(i) {
      const r = this.$refs.mcontDom;
      const el = document.getElementById("dom-group__" + i);
      if (el) {
        const offset = el.offsetTop;
        r.scrollTop = offset;
      }
    },

    onClickDomItem(i, j) {
      const g = this.domGroups[i];
      const item = g && g.items[j];
      if (item) {
        this.confirm(item);
      }
    },

    onClickSearchItem(item) {
      // 修改城市
      if (item && item.cityFlag == 1) {
        this.confirm(item);
        this.unsearch();
        return void 0;
      }

      // 跳转产品详情页
      const page = {
        url: "/",
        query: {
          analyseSourceEntry: env.hlsource || "",
        },
      };

      switch (item.category) {
        case CATEGORY["门票"]:
          page.url = "/scenic-detail";
          page.name = "scenic-detail";
          page.query["scenicSpotId"] = item.scenicSpotId;
          break;
        case CATEGORY["跟团游"]:
          page.url = "/tour-group-detail";
          page.name = "tour-group-detail";
          page.query["productId"] = item.productId;
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic";
          page.name = "hotel-scenic";
          page.query["productId"] = item.productId;
          break;
      }

      // this.$router.push(page)
      this.$page.push(page);
    },
    onClickSearchItemV2(item) {
      // 修改城市
      if (item && item.type == 1) {
        this.confirm(item);
        this.unsearch();
        return void 0;
      } else {
        if (this.typeOfBusiness == 0) {
          // 跳转产品详情页

          if (item && item.type == 2) {
            const page = {
              url: "/",
              query: {
                analyseSourceEntry: env.hlsource || "",
                keyword: encodeURIComponent(item.content),
                scenicSpotId: item.scenicSpotId || "",
              },
            };
            page.url = "/common-list";
            page.name = "common-list";
            // switch (item.category) {
            //   case CATEGORY["门票"]:
            //     page.url = "/scenic-detail";
            //     page.name = "scenic-detail";
            //     page.query["scenicSpotId"] = item.scenicSpotId;
            //     break;
            //   case CATEGORY["跟团游"]:
            //     page.url = "/tour-group-detail";
            //     page.name = "tour-group-detail";
            //     page.query["productId"] = item.productId;
            //     break;
            //   case CATEGORY["酒景"]:
            //     page.url = "/hotel-scenic";
            //     page.name = "hotel-scenic";
            //     page.query["productId"] = item.productId;
            //     break;
            // }

            // this.$router.push(page)
            if (item.scenicSpotId) {
              this.$page.push(page);
            }
          }
          if (item && item.type == 3) {
            const page = {
              url: "/",
              query: {
                analyseSourceEntry: env.hlsource || "",
                keyword: encodeURIComponent(item.content),
              },
            };
            // if (item.cityName && item.cityCode) {
            //   let city = {
            //     cityName: item.cityName,
            //     cityCode: item.cityCode,
            //   };
            //   LocalData.setCity(city);
            // }
            page.url = "/common-list";
            page.name = "common-list";
            this.$page.push(page);
          }
        } else if (this.typeOfBusiness == 2) {
          //门票
          const page = {
            url: "/",
            query: {
              analyseSourceEntry: env.hlsource || "",
            },
          };
          page.url = "/scenic-detail";
          page.name = "scenic-detail";
          page.query["scenicSpotId"] = item.scenicSpotId;
          this.$page.push(page);
        } else if (this.typeOfBusiness == 13) {
          //综合页点击景点
          if (item && item.type == 2) {
            this.$emit("search", {
              value: item.content,
              scenicSpotId: item.scenicSpotId || "",
            });
          }
          //综合页点击关键词
          if (item && item.type == 3) {
            this.$emit("search", { value: item.content });
          }
        }
      }
    },
    confirm(item) {
      console.log("------ select city comp ------\n", item);

      if (!item) return;

      this.addToRecent(item);

      // type: 类型，2-国家，4-城市，5-机场
      // name: 名称，机场全称
      // shortname: 类型是机场时=shortairport，其他类型=name
      // shortairport: 类型为机场时，机场简称
      // cname: 上一级名称，类型为机场且当前城市有一个以上的机场时，此字段为空
      // code: 三字码
      // international: 1-国际
      //
      if (this.__callback) {
        this.__callback(item);
      }

      this.$emit("confirm", {
        cityName: item.name || item.cityName,
        cityCode: item.code || item.cityCode,
        url: item.url,
      });
    },

    // 添加到最近搜索
    addToRecent(item) {
      let ls = this.recent;
      for (let i = 0; i < ls.length; i++) {
        if (ls[i].name === item.name) {
          ls.splice(i, 1);
          break;
        }
      }
      ls.unshift({
        // type: item.type,
        hl: item.hl,
        name: item.name,
        hot: item.hot,
        code: item.code,
        // shortname: item.shortname,
        // shortairport: item.shortairport,
        // cname: item.cname,
        // textlength:
        //     (item.name && item.name.length) ||
        //     (item.shortname && item.shortname.length),
        // international: item.international
        // tag: item.tag,
      });
      if (ls.length > 8) {
        ls = ls.slice(0, 8);
      }
      this.recent = ls;
      LocalData.setRecentCity(ls);
    },

    // 清除最近搜索
    clearRecent() {
      LocalData.clearRecentCity();
      this.recent = [];
    },

    handleSubmit(e) {
      e.preventDefault();
      this.$emit("search", {
        value: _.get(e, "target[0].value", ""),
      });
    },
  },
};

function __hl(q, s) {
  console.log(q, s);
  let n = q.length;
  if (s) {
    if (s.slice(0, n) === q) {
      return [1, s.slice(n)];
    }
  }
  return [0, s];
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_vars.scss";
.backICon {
  width: 9 * $px;
  height: 18 * $px;
  margin-right: 17 * $px;
}
.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #fefffe;
  padding-top: calc(6px + constant(safe-area-inset-top));
  padding-top: calc(6px + env(safe-area-inset-top));
}

.search-bar-wrapper {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  height: 56 * $px;
  padding-top: 8 * $px;
  padding-bottom: 12 * $px;
  padding-left: 20 * $px;
  padding-right: 20 * $px;
  display: flex;
  align-items: center;
}
.search-bar-wrapper.active {
  padding-right: 12 * $px;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
}
.search-bar-wrapper.inactive .search-bar-cancel {
  display: none;
}
.search-bar-cancel {
  font-size: 14 * $px;
  font-weight: 400;
  color: rgba(25, 133, 255, 1);
  line-height: 20 * $px;
  margin-left: 12 * $px;
}
.search-bar {
  flex: 1;
  height: 36 * $px;
  background: #f7f9fc;
  border-radius: 18 * $px;
  display: flex;
  align-items: center;
  padding: 0 12 * $px;
  form {
    width: 100%;
  }
  input::-webkit-input-placeholder {
    color: rgba(51, 56, 76, 0.3);
  }
}
.search-bar-icon-0 {
  width: 14 * $px;
  height: 14 * $px;
}
.search-bar-icon-1 {
  width: 14 * $px;
  height: 14 * $px;
  opacity: 0.4;
}
.search-bar-input {
  width: 100%;
  flex: 1;
  font-size: 16 * $px;
  line-height: 20 * $px;
  padding: 0 8 * $px;
  border: none;
  background: #f7f9fc;
}

.main-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.top-tab-bar {
  position: relative;
  z-index: 1;
  background: #fff;
  height: 42 * $px;
  box-sizing: border-box;
  padding-left: 40 * $px;
  padding-right: 40 * $px;
  padding-bottom: 2 * $px;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-around;
}
.top-tab-bar-item {
  position: relative;
  height: 22 * $px;
  font-size: 16 * $px;
  font-weight: 500;
  color: rgba(38, 38, 38, 1);
  line-height: 22 * $px;
  padding-top: 8 * $px;
}
.top-tab-bar-item.active {
  color: rgba(25, 133, 255, 1);
}
.top-tab-bar-item.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10 * $px;
  width: 100%;
  height: 4 * $px;
  background: rgba(25, 133, 255, 1);
  border-radius: 2 * $px;
}
.new-body {
  padding: 14 * $px 20 * $px;
  .defaultList {
    &-title {
      font-size: 15 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #262626;
      margin-bottom: 16 * $px;
    }
    &-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      &-item {
        background: #f7f9fc;
        border-radius: 15 * $px;
        padding: 8 * $px 12 * $px;
        font-size: 14 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(52, 56, 75, 0.75);
        margin-right: 12 * $px;
        margin-bottom: 12 * $px;
      }
    }
  }
}
.m-body {
  position: relative;
  flex: 1;
  overflow: hidden;
}
.m-body-dom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.m-body-int {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
}

.search-res-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.5);
  background: #ffffff;
}
.search-res-overlay.hidden {
  display: none;
}

.nav {
  height: 100%;
  width: 88 * $px;
  background: rgba(246, 246, 246, 1);
  overflow-y: auto;
}
.nav-item {
  height: 48 * $px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(38, 38, 38, 1);
  font-size: 15 * $px;
  font-size: 300;
}
.nav-item.active {
  background: rgba(255, 255, 255, 1);
  font-weight: 500;
}

.m-content {
  position: relative;
  z-index: 0;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 80 * $px;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.group-hd {
  position: sticky;
  z-index: 1;
  top: 0;
  background: #fff;
  padding: 20 * $px;
  padding-bottom: 10 * $px;
  display: flex;
  align-items: center;
}
.group-name {
  font-size: 15 * $px;
  font-weight: 500;
  color: rgba(38, 38, 38, 1);
  line-height: 21px;
}
.group-action {
  font-size: 12 * $px;
  color: #999;
}
.group-tag {
  margin-left: 8 * $px;
  border-radius: 2px;
  padding: 0 2 * $px;
  font-size: 10 * $px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
}
.group-items {
  padding-left: 20 * $px;
  padding-top: 10 * $px;
  display: flex;
  flex-wrap: wrap;
}
.group-item {
  margin-right: 10 * $px;
  margin-bottom: 10 * $px;
  width: 76 * $px;
  height: 36 * $px;
  padding: 1 * $px 4 * $px;
  box-sizing: border-box;
  background: rgba(247, 249, 252, 1);
  border-radius: 6px;
  font-size: 14 * $px;
  font-weight: 400;
  color: rgba(38, 38, 38, 1);
  text-align: center;
  line-height: 34 * $px;
  white-space: no-wrap;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(200, 200, 200, 0.5);
}
.group-item.hl-cur-item {
  color: #1985ff;
  border: 1px solid rgba(25, 133, 255, 1);
}
.group-item-tag {
  position: absolute;
  top: -4 * $px;
  right: -4 * $px;
  border-radius: 2px;
  padding: 0 4 * $px;
  font-size: 10 * $px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 14 * $px;
}
.cell-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell-text-5 {
  font-size: 12 * $px;
}
.cell-text-6 {
  font-size: 10 * $px;
  line-height: 12 * $px;
  white-space: pre-line;
}
.cell-text-7,
.cell-text-8,
.cell-text-9,
.cell-text-10 {
  font-size: 8 * $px;
  line-height: 12 * $px;
  white-space: pre-line;
}
.group-item-index {
  width: 36 * $px;
  height: 36 * $px;
  text-align: center;
  line-height: 36 * $px;
  margin-right: 7 * $px;
  margin-bottom: 7 * $px;
  background: rgba(247, 249, 252, 1);
  border-radius: 6px;
  font-size: 14 * $px;
  font-weight: 400;
  color: rgba(38, 38, 38, 1);
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(200, 200, 200, 0.5);
}

.scroll-to-top {
  position: fixed;
  z-index: 0;
  bottom: 40 * $px;
  right: 28 * $px;
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.scroll-to-top-img {
  width: 30 * $px;
  max-width: 48px;
  height: auto;
}

.search-res {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.search-no-result {
  padding-top: 64 * $px;
  text-align: center;
}
.search-no-result-img {
  width: auto;
  height: 140 * $px;
}
.search-no-result-txt {
  font-size: 14 * $px;
  margin-top: 20 * $px;
  color: rgba(187, 187, 187, 1);
}
.si-1,
.si-2 {
  display: flex;
  height: 46 * $px;
  align-items: center;
  // border-bottom: 1px solid #f0f0f0;
}
.si-1 {
  margin-left: 20 * $px;
  padding-right: 16 * $px;
}
.si-2 {
  margin-left: 48 * $px;
  padding-right: 16 * $px;
}
.si-main {
  flex: 1;
  padding: 0 8 * $px;
  display: flex;
  align-items: center;
  span:not(.si-text-hl) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.si-code {
  color: #8d8d8d;
  margin-left: 12 * $px;
}
.si-type-tag {
  padding: 0 4 * $px;
  line-height: 16 * $px;
  font-size: 11 * $px;
  font-weight: 400;
  border-radius: 1px;
}
.si-1 .si-type-tag {
  background: #1985ff;
  color: #fff;
}
.si-2 .si-type-tag {
  color: #1985ff;
  border: 1px solid #1985ff;
}
.si-distance {
  font-size: 16 * $px;
  color: rgba(187, 187, 187, 1);
}
.si-hot-tag {
  padding: 0 4 * $px;
  font-size: 10 * $px;
  line-height: 14 * $px;
  color: #fff;
  background: linear-gradient(
    135deg,
    rgba(255, 150, 0, 1) 0%,
    rgba(255, 104, 20, 1) 100%
  );
  border-radius: 2px;
}
.si-text-hl {
  white-space: nowrap;
  color: rgba(25, 133, 255, 1);
}
.n-click,
.n-click .si-code,
.n-click .si-text-hl {
  color: #bbb;
}
.imgIcon {
  width: 22 * $px;
  height: 22 * $px;
  margin-right: 12 * $px;
}
</style>
