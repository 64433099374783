// import dt from "@/utils/datetime";
import clone from "@/utils/clone";

// 过滤出热门城市
export function getHotCitys(citysData) {
  return citysData.filter((item) => {
    return item.hot > 0;
  });
}

// 分类产品数据合并
export function mergePTypesData(oldData, objData) {
  let newData = {};
  let _oldData = clone(oldData);

  if (_oldData.pros && _oldData.pros.length > 0) {
    newData.more = objData.more;
    newData.moreDesc = objData.moreDesc;
    newData.productType = objData.productType;
    newData.pageNo = _oldData.pageNo + 1; // 用于分页加载数据
    newData.pros = _oldData.pros.concat(objData.pros);
  } else {
    Object.assign(newData, { pageNo: 0 }, objData);
  }

  return newData;
}
