<template>
  <div class="popup a-fix-popup">
    <!-- 挂载到 #app 节点下 -->
    <hl-popup v-model="show" position="right" :round='false' :style="{ width: '100%', height: '100%' }" get-container="body"
      :overlay-style='{zIndex:360}' :overlay="false">
      <div class="search-city-popup fx-col">
        <!-- <div class="popup-top">
          <div class="title">
            <p>{{ title }}</p>
            <i class="close" @click="close"></i>
          </div>
        </div> -->
        <div class="popup-bottom">
          <select-city-comp ref="initDataRef" :typeOfBusiness='typeOfBusiness' :dep="type === 'destination' ? 0 : 1" :placeholder="placeholder"
            @confirm="handleConfirm" @search="handleSearch" @close='close' :pageCityObj='pageCityObj'  />
        </div>
      </div>
    </hl-popup>
  </div>
</template>

<script>
import Vue from "vue";

import { Popup } from "huoli-ui";

Vue.use(Popup);

import debounce from "@/utils/debounce";
import get from "@/utils/get";
import localStorage from "@/utils/localStorage";
import service from "../../service";
import * as Filter from "@/service/filter";
import * as Utils from "@/service/utils";
import * as LocalData from "@/service/localData";
import selectCityComp from "./selectCityComp";

export default {
  components: {
    selectCityComp,
  },

  props: {
    banners: null,
    typeOfBusiness: {
      type: Number, // 0: "不限（主页）", 13:‘综合页’ 1: "酒店", 2: "门票",4: "美食",  10: "跟团游",11: "酒店套餐",13综合页
      default: 0,
    },
    title: {
      type: String,
      default: "选择跟团游目的地或景点",
    },
    type: {
      type: String,
      default: "destination", // destination 目的地， origin 出发地
    },
    placeholder: {
      type: String,
      default: "搜索城市名称（中文或拼音）",
    },
  },

  data() {
    return {
      isLoading: false,
      show: false, // 是否显示弹出框
      citysArr: [],
      lastSearchArr: [], // 最近搜索结果
      hotCityArr: [], // 热门城市
      showSearch: false, // 是否显示搜索结果
      searchValue: "",
      searchResults: [],
      pageCityObj: {
        cityName:
          LocalData.getCity().default == true
            ? LocalData.getOriginCity().cityName
            : LocalData.getCity().cityName,
        cityCode:
          LocalData.getCity().default == true
            ? LocalData.getOriginCity().cityCode
            : LocalData.getCity().cityCode,
      },
    };
  },

  mounted() {},

  methods: {
    // 初始化数据
    initData() {
      // 获取缓存中的数据
      this.lastSearchArr = localStorage.get("hltrip.city.lastsearch") || [];
      this.hotCityArr = localStorage.get("hltrip.city.hot") || [];
    },
    // 是否显示国家选择弹出框
    open() {
      this.initData();
      this.show = true;
      setTimeout(() => {
        let obj = {
          cityName:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityName
              : LocalData.getCity().cityName,
          cityCode:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityCode
              : LocalData.getCity().cityCode,
        };
        this.$refs.initDataRef.getDefaultList(obj);
        this.pageCityObj = {
          cityName:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityName
              : LocalData.getCity().cityName,
          cityCode:
            LocalData.getCity().default == true
              ? LocalData.getOriginCity().cityCode
              : LocalData.getCity().cityCode,
        };
      }, 300);
    },
    close() {
      this.show = false;
    },

    clickAd(banner) {
      this.$emit("click-ad", banner);
    },

    onInput(val) {
      this.searchValue = val;

      if (val && val.length > 0) {
        debounce((query) => {
          this.showSearch = true;
          this.handleQueryChange(query);
        }, 500)(val);
      } else {
        this.showSearch = false;
      }
    },

    loadCityData() {
      return service
        .r215002({ dep: this.type === "origin" ? 1 : 0 })
        .then((r) => get(r, "res.bd.data"))
        .then((r) => {
          // commit(USERINFO_SET, r);
          let hotCitys = Filter.getHotCitys(r);

          this.hotCityArr = hotCitys;
          localStorage.set("hltrip.city.hot", hotCitys);

          return r;
        })
        .catch(() => {
          return null;
        });
    },

    handleConfirm(item) {
      Utils.setTimeoutStoreFn(
        [
          () => {
            if (this.type === "destination") {
              LocalData.setCity(item);
            } else if (this.type === "origin") {
              LocalData.setOriginCity(item);
            }
          },
        ],
        () => {
          this.close();
          this.$emit("on-confirm-city", item);
        }
      );
    },

    handleSearch(item) {
      this.close();
      this.$emit("search", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";
@import "../../scss/_mixs.scss";

.hl-overlay {
  z-index: 360 !important;
}
.hl-popup {
  z-index: 362 !important;
}
.search-city-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .popup-top {
    .title {
      position: relative;
      padding: 14 * $px 0;
      p {
        margin: 0;
        text-align: center;
        font-size: 18 * $px;
        font-weight: 500;
        color: #262626;
        line-height: 25 * $px;
      }

      i {
        position: absolute;
        top: 19 * $px;
        left: 16 * $px;
        display: inline-block;
        width: 14 * $px;
        height: 14 * $px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        margin-right: 8 * $px;
        transform: translate3d(0, 0px, 0);
        background-image: url("https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/close-1@3x.png");
      }
    }
  }

  .popup-bottom {
    flex: 1;
    overflow: auto;
    .conditions {
      .box {
        padding: 25 * $px 20 * $px 0 20 * $px;

        &-title {
          font-size: 15 * $px;
          font-weight: 500;
          color: #262626;
          line-height: 21 * $px;
          margin: 0 0 20 * $px 0;
        }

        ul {
          @include clear-fix;

          li {
            margin: 0 10 * $px 10 * $px 0;
            box-sizing: border-box;
            height: 36 * $px;
            background: #f6f6f6;
            border-radius: 2px;
            float: left;

            span {
              font-size: 14 * $px;
            }
          }
        }
      }

      .recent-search {
        li {
          width: 76 * $px;
        }
      }

      .hot-city {
        li {
          width: 76 * $px;
        }
      }

      .letter-search {
        li {
          width: 36 * $px;
          margin: 0 5 * $px 5 * $px 0 !important;
        }
      }
    }

    .results {
      box-shadow: 0 10 * $px 10 * $px -8 * $px rgba(0, 0, 0, 0.05) inset;

      .list-box {
        box-sizing: border-box;
        padding: 20 * $px;
      }

      .no-data {
        min-height: 350 * $px;
        font-size: 13 * $px;
        color: #6383a2;
        line-height: 13 * $px;
      }
    }
  }
}
</style>
